.climable-div {
    padding: 1rem;
    position: relative;
    background: linear-gradient(65deg,#de6b38 25%,#7163bb 85%);
    padding: 2px;
    border-radius: 10px;
  }
  
.climable {
    background: #141617;
    color: white;
    border-radius: 10px;
}
.jones-stake-div {
    border: 1px solid white;
    border-radius: 10px;
}
.jones-stake-balance-div {
    color: chocolate;
    background-color: #262829;
    border-radius: 20px;
    font-size: 13px;
    
}
.bg-primary-gradient {
    background-image: linear-gradient(65deg,#7163bb 25%,#de6b38 85%);
}
.connect-wallet-btn{
    height: 60px;
    border-radius:10px;
}
.connect-wallet-btn:hover{
    cursor: pointer;
    background-image: linear-gradient(65deg,#de6b38 25%,#7163bb 85%);
}
.mouseovered:hover {
    cursor: pointer;
}
.active{
    color: white;
    border-bottom: 2px solid white;
}
.liquidity-div {
    background-color: #3a2e40;
    padding-right: 80px;
}
.balance-btn-div {
    position: relative;
    $border: 1px;
    color: black;
    background-clip: padding-box; 
    border: solid $border transparent; 
    background-color: #262829;
    padding: 0px 10px;
    border-radius: 20px;
    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(65deg, #de6b38 25%, #7163bb 85%,);
    }
}

.balance-btn {
    display: flex;
    align-items: center;
    background-image: linear-gradient(65deg, #de6b38 25%, #7163bb 85%,);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.balance-btn:hover {
    cursor: pointer;
}