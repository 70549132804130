.staking {
    position: relative;
    color: white;
    .full-width {
        background-color: #141617;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        .grid-linear1 {
            background-image: radial-gradient(41.29% 41.29% at 50% 51.77%, rgb(202, 94, 50) 15.1%, rgba(255, 131, 32, 0) 94.73%);
            opacity: .25;
            width: 105vw;
            height: 150vh;
            top: 10%;
            left: -60%;
            position: absolute;
            pointer-events: none;
        }
        .grid-linear2 {
            background-image: radial-gradient(46.13% 46.13% at 50% 51.77%, rgb(147, 81, 118) 16.66%, rgba(98, 59, 81, 0) 97.39%);
            opacity: .3;
            width: 105vw;
            height: 150vh;
            top: -10%;
            left: -65%;
            position: absolute;
            pointer-events: none;
        }
        .grid-linear3 {
            background-image: radial-gradient(46.13% 46.13% at 50% 51.77%, rgb(42, 40, 102) 16.66%, rgb(10, 11, 60) 45.31%, rgba(10, 11, 60, 0) 95.83%);
            opacity: .4;
            width: 105vw;
            height: 150vh;
            top: -25%;
            left: -60%;
            position: absolute;
            pointer-events: none;
        }
    }
    .staking-total-row {
        padding-left: 0;
        padding-right: 0;
    }
    .staking-total-col {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    
    .bg-total-custom {
        background-color: #111214;
        border-radius: 10px;
    }
    
    .bg-custom {
        background-color: #111214;
        border-radius: 10px;
    }
    .bg-custom-div:hover {
        cursor:pointer;
    }
    
    .disclaimer-our-docs {
        color: white;
    }
    
    .disclaimer-custom {
        background-color: #111214;
        border-radius: 10px;
    }
    .learn-txt {
        color: white;
        text-decoration: none;
    }
    .learn-txt:hover {
        color: #de6b38;
    }
    a{
        color: white !important;
        text-decoration: none !important;
    }
}


@media (min-width: 1180px){
    .staking-child {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
}
@media (max-width: 760px) {
    .staking-child {
        padding-left: 1rem;
        padding-right: 1rem;
    }

}