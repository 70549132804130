.chart1 {
    width: 100%;
    height: 250px;
}
.history-btns {
    background-color: #262829;
    border-radius: 7px;
}
.history-btns:hover {
    cursor: pointer;
}
.history-btns:active {
    color: chocolate;
}
.history-btns:focus {
    color: chocolate;
}