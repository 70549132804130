body{
    overflow-x: hidden;
}
.landing{
    position: relative;
    // overflow: hidden;
    color: white;
    .full-width {
        background-color: #141617;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        .grid-linear1 {
            background-image: radial-gradient(41.29% 41.29% at 50% 51.77%, rgb(202, 94, 50) 15.1%, rgba(255, 131, 32, 0) 94.73%);
            opacity: .25;
            width: 105vw;
            height: 150vh;
            top: 10%;
            left: -60%;
            position: absolute;
            pointer-events: none;
        }
        .grid-linear2 {
            background-image: radial-gradient(46.13% 46.13% at 50% 51.77%, rgb(147, 81, 118) 16.66%, rgba(98, 59, 81, 0) 97.39%);
            opacity: .3;
            width: 105vw;
            height: 150vh;
            top: -10%;
            left: -65%;
            position: absolute;
            pointer-events: none;
        }
        .grid-linear3 {
            background-image: radial-gradient(46.13% 46.13% at 50% 51.77%, rgb(42, 40, 102) 16.66%, rgb(10, 11, 60) 45.31%, rgba(10, 11, 60, 0) 95.83%);
            opacity: .4;
            width: 105vw;
            height: 150vh;
            top: -25%;
            left: -60%;
            position: absolute;
            pointer-events: none;
        }
    }
    .landing-child{
        overflow: hidden;
    }
    .bg-dark-custom {
        background-color: #111214;
    }
    .bg-black-custom {
        background-color: #1b1d1f;
    }
    .bg-danger-custom {
        background-color: #101213cc;
        background-image: linear-gradient(to top left,var(--tw-gradient-stops));
        --tw-gradient-to: #101213cc;
        --tw-gradient-from: #3a2a47bf;
        --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    }
    .bg-info-custom {
        background-color: #101213cc;
        --tw-gradient-from: #1d2151cc;
        --tw-gradient-to: #101213cc;
        --tw-gradient-stops: var(--tw-gradient-from),transparent,var(--tw-gradient-to);
        background-image: linear-gradient(to top left,var(--tw-gradient-stops));
    }
    .bg-primary-custom {
        background-color: #101213cc;
        --tw-gradient-from: rgba(240,68,56,.3);
        --tw-gradient-to: #101213cc;
        --tw-gradient-stops: var(--tw-gradient-from),transparent,var(--tw-gradient-to);
        background-image: linear-gradient(to top left,var(--tw-gradient-stops));
    }
    a{
        color: white !important;
        text-decoration: none !important;
    }
    .fs-5-custom {
        font-size: 13px !important;
    }
    .fs-6-custom {
        font-size: 11px !important;
    }
    .bg-primary-gradient {
        background-image: linear-gradient(65deg,#7163bb 25%,#de6b38 85%);
    }
    .bg-info-gradient {
        --tw-gradient-from: #3f7b8e;
        --tw-gradient-to: #13323c;
        --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
    }
    
    .bg-purple {
        background-color: #34253f;
        color: white;
    }
    .text-gray {
        color: rgb(196 196 196/var(--tw-text-opacity));
    }
    .rounded-full {
        border-radius: 20px;
    }
    .card {
        // padding: unset !important;
        border-radius: 10px !important;
    }
    .card-hover-border:hover{
        border: 0.1px solid #79727238;
    }
    .Mainnet-div {
        background-color: #262829;
        padding: 0px 10px;
        border-radius: 20px;
    }
    .jauraImg {
        inset: 0px; 
        box-sizing: border-box; 
        padding: 0px; 
        border: none; 
        margin: auto; 
        display: block; 
        width: 32px; 
        height: 32px; 
    }
    .jaura-Img {
        inset: 0px; 
        box-sizing: border-box; 
        padding: 0px; 
        border: none; 
        display: block; 
        width: 41px; 
        height: 41px; 
    }
    .jdpxETHImg {
        inset: 0px; 
        box-sizing: border-box; 
        padding: 0px; 
        border: none; 
        margin: auto; 
        display: block; 
        width: 54px;
    }
    .dpxETHImg {
        inset: 0px; 
        box-sizing: border-box; 
        padding: 0px; 
        border: none; 
        display: block; 
        width: 67px;
    }
    .jaura-card{
        height: 250px !important;
    }
    .new-button-parent {
        background-color: #34253f;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 42px;
    }
    .new-button-parent-info {
        background-color: #1d2151cc;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .new-button-parent-primary {
        height: 43px;
        background-color: #742825;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .auraBanner-div{
        position: relative;
        background-color: #101213cc !important;
        height: 250px !important;
    }
    .auraBanner-img{
        width: 333px;
        margin-right: -10px;
        position: absolute;
        right: 0;
    }
    .btn-group-custom {
        border-radius: 7px;
        background-color: #101213cc;
        box-shadow: 1px 2px 3px rgba(48, 47, 47, 0.137), -1px 3px rgba(48, 47, 47, 0.137);
    }
    .btn-group-custom Button {
        background: transparent;
        border: transparent;
    }
    .btn-group-custom Button:hover {
        background: transparent;
        border: transparent;
    }
    .btn-group-custom Button:focus {
        background: #1b1d1f;
        color: white !important;
        border: transparent;
    }
    .btn-group-custom Button.active {
        background: #1b1d1f;
        color: white !important;
        border: transparent;
    }
    .learn-txt {
        color: white;
        text-decoration: none;
    }
    .learn-txt:hover {
        color: #de6b38;
    }
}

@media (min-width: 1180px){
    .landing-child{
        padding-left: 6rem;
        padding-right: 6rem;
    }
    
}
@media (max-width: 990px){
    .auraBanner-parent{
        display: none !important;
    }
}
